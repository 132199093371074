@import "config";

.vaultFilter {
  > form {
    display: flex;
    align-items: center;
    height: 2.4em;
    > div {
      flex: 1;
      height: 100%;
      &:first-child {
        flex: 1.5;
      }
      + div {
        margin-left: $contentDefaultIndent;
      }
      button {
        padding: 0 0.7em;
        &:global(.btn) {
          &-secondary {
            &[disabled] {
              border-color: $uiBorderColor;
              background: $uiBorderColor;
              color: $uiDarkColor;
            }
          }
        }
      }
    }
  }
  .resetButtonGroup {
    flex: none;
    width: auto;
  }
}
