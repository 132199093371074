@import "config";
@import "animations";

.vaultPage {
  .pageHeaderContent {
    display: flex;
    .createFolder {
      + div {
        margin-left: $contentDefaultIndent;
      }
    }
  }
  .actionsButtons {
    > button {
      padding: 0.65em 0.85em;
    }
  }
  .foldersRow {
    padding: 0 $contentDefaultIndent $contentDefaultIndent;
    button {
      position: relative;
      margin: $contentDefaultIndent;
      color: $uiDarkColor;
      svg {
        flex: none;
        font-size: 1.6em;
      }
      > div {
        display: flex;
        align-items: center;
        .folderName {
          max-width: 20em;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .folderFilesCount {
          display: inline-block;
          margin-left: 1em;
          border: 1px solid transparent;
          background-color: $uiDarkColor;
          color: $uiWhiteColor;
        }
      }
      .delete {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        padding: $contentDefaultIndent / 2;
        transform: translate(50%, -50%);
        border-radius: 50%;
        opacity: 0;
        background-color: $uiNegativeColor;
        color: $uiWhiteColor;
        font-size: 0.75rem;
        svg {
          font-size: 1.2em;
        }
      }
      &:hover .delete {
        opacity: 1;
        filter: grayscale(0.25);
      }
      &:hover .delete:hover {
        filter: none;
      }
    }
  }
  [data-wait] {
    animation: rotate 2s linear infinite;
  }
}
