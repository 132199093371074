@import "config";

.editVaultFileWindowCommentsBlock {
  position: relative;
  flex-flow: column nowrap;
  height: 26.5em;
  overflow: auto;
  :global(.input) {
    &-group-prepend {
      display: none;
    }
  }
  &,
  > div {
    margin: 0;
    padding: 0 $contentDefaultIndent * 2;
    > div {
      padding: 0;
    }
  }
  [data-placeholder] {
    margin-top: 0 !important;
  }
  input {
    height: 100%;
    border-bottom-right-radius: 0;
  }
}
