@import "config";

@media (max-width: $simplifiedLayoutMaxWidth) {
  .changeFolderSelector {
    > button {
      svg + span {
        display: none;
      }
    }
  }
}
