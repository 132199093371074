@import "config";

.editVaultFileWindow {
  width: 650px;
  &.commentsMode {
    :global(.modal) {
      &-body {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
  .folderPathGroup {
    > div {
      word-break: break-all;
    }
  }
  .fileLinkContainer {
    max-width: 100%;
    margin: 2em 1em;
    font-size: 1.2em;
    text-align: center;
    word-break: break-all;
  }
  :global(.input) {
    &-group-prepend {
      > span {
        width: 84px;
      }
    }
  }
}
